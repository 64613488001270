import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import PropTypes from "prop-types"
import MySignature from "../images/Signature.svg"
import "../Style/TitleUnderlined.css"

const TitleUnderlined = props => (
 <div>
     <div id="TitleWrapper">
        <div className="Title">{props.title}</div>
        <div className={props.color}></div>
     </div>
 </div>
)

export default TitleUnderlined