import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import MyLogo from "../components/Logo"
import MyLogoFixed from "../components/LogoFixed"
import MySignature from "../images/Signature.svg"

import "../Style/EssayPage.css"
import TitleUnderlined from "../components/TitleUnderlined"

const EnglishOverRussian = ({data}) => (
 <div> 

    <SEO 
    title="English over Russian? — Phil Murphy"
    />

    <Helmet>

        <meta name="robots" content="noindex" />
        <link rel="subresource" href="../Style/EssayPage.css" />

        <meta name="twitter:description" content="Phil Murphy">
        </meta>

        <meta name="og:description" content="Phil Murphy">
        </meta>

        <meta name="description" content="Phil Murphy">
        </meta>

        <meta name="image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image:alt" content="Phil Murphy">
        </meta>

        <meta name="thumbnail" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="og:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

    </Helmet>



    


    <div className="EssayPageWrapper">

        <essayheader><MyLogoFixed /></essayheader>

        <essaycontent>


        <p className="EssayTitlePage">English over Russian?</p>

        <div className="P_style">

        <p>So why would I prefer to use English over Russian?</p>

        <p>Well, I have to make a confession:</p>

        <p>And my answer would be pretty cheap. After years of learning about both sides of 2 different cultures, I just feel better when I'm sticking to English.</p>

        <p>I don't enjoy using my native one. For 19 years, I couldn't understand it. I couldn't get into it. But I do feel joy when I watch, play, and listen to something in English. That's why I always prefer to have English as the primary language, both verbally and in writing these days.</p>

        <p>And yeah, even though I can speak Russian perfectly, I can't express my thoughts and feelings the same way I can through English. It's just hard for me. It's literally like having 2 different personalities.</p>

        <p>English gives me much more confidence that I can transfer my message the right way without losing something in between. The same happens when someone says something to me. And clearly, I'm still learning, but it's already been a pretty long ride.</p>

        <p>English structure is simply easier for me. You can't hide something behind some tricky words as you can in Russian. It's clear and straightforward.</p>

        <p>And I am definitely not saying Russian is a bad language. It has its own advantages and bright sides. But it feels foreign to me.</p>

        <p>In the last 4 years, I tried hard to act, write, and speak only in English (work, entertainment, and anything online). And it played its role. After having thousands of words written down and having hours of actually speaking with natives, now I can understand 99,9% of what people say, as well as express almost any thought. And it feels great.</p>

        <p><i>An important note here would be that I'm really bad at instant translation. I'm trying to learn the same way I'd do it being in IRL society and mostly just remember words & sentences subconsciously, which affects the ability to keep things in both languages at the same time.</i></p>

        <p>And that's all just gave me a sense of what it's like to understand both languages and just choose the one I feel better about. After all, English takes over for me.</p>

        <p>In conclusion, I just want to say that I have nothing against the Russian language or its use. It's a wonderful language that I just can't love. And that's why English would always be a better choice in most of the cases.</p>

  
 



        </div>


        </essaycontent>







        <essayfooter>
        <div>
          <div className="Signature"><img src={MySignature} width="166" /></div>
        </div>
        </essayfooter>

    </div>

    </div>
)

export default EnglishOverRussian