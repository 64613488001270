import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import PropTypes from "prop-types"
import MySignature from "../images/Signature.svg"

import "../Style/Signature.css"

const Signature = () => (
 <div>
     <div className="Signature"><img src={MySignature} width="166" /></div>
 </div>
)

export default Signature