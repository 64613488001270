import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'
import PropTypes from "prop-types"
import Logo from "../images/Logo.png"
import "../Style/Logo.css"

const MyLogoFixed = () => (
    <div className="FixedlogoWrapper">
        <div id="Wrapper">
            
            <logo><div className="LogoPlaceholderFixed"><Link to="/"><img src={Logo} width="87" height="95" /></Link></div></logo>
            <name><div className="Name"><Link to="/">Phil Murphy</Link></div></name>
            <twitter><div className="TwitterLink"><a href="https://twitter.com/NERFWRLD" target="_blank" rel="noopener">@NERFWRLD</a></div></twitter>
        </div>

   
            


 </div>
)

export default MyLogoFixed